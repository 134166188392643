import { Link } from '@remix-run/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '#app/components/container.tsx';
import { Logo } from '#app/components/logo.tsx';
import { Button } from '#app/components/ui/button.tsx';
import { Icon } from '#app/components/ui/icon.tsx';
import { useChangeLanguageURL } from '#app/hooks/use-change-language.ts';
import { cn } from '#app/utils/misc.tsx';
import { analytics } from '#app/utils/segment.ts';
import { type IconName } from '@/icon-name';

const ENV = process.env.NODE_ENV;

const NAV_CONFIG = [
	{
		label: 'nav.projects',
		to: '/projects',
		key: 'PROJECTS',
	},
	{
		label: 'nav.contact',
		to: '/contact',
		key: 'CONTACT_US',
		button: true,
	},
];

export function Header({
	panelId,
	icon,
	expanded,
	onToggle,
	toggleRef,
	invert = false,
}: {
	panelId: string;
	icon: IconName;
	expanded: boolean;
	onToggle: () => void;
	toggleRef: React.RefObject<HTMLButtonElement>;
	invert?: boolean;
}) {
	const { t } = useTranslation();
	const { to, langSegment } = useChangeLanguageURL();

	return (
		<Container>
			<div className="flex items-center justify-between">
				<Link to="/" aria-label="Home">
					<Logo
						className={cn('h-11', {
							'fill-neutral': invert,
						})}
					/>
				</Link>
				<div className="flex items-center gap-x-8">
					{NAV_CONFIG.map(({ label, to: path, key, button }) => {
						if (key === 'PROJECTS' && ENV !== 'development') return null;

						return (
							<Button
								asChild
								key={key}
								variant={button || expanded ? 'default' : 'link'}
								className={'transition-colors'}
							>
								<Link
									onClick={() => {
										analytics.track(`${label} Clicked`, { location: 'Header' });
									}}
									to={langSegment + path}
								>
									{t(label)}
								</Link>
							</Button>
						);
					})}

					<Link
						className={cn(
							'text-sm font-semibold text-primary',
							invert ? 'text-primary-light' : 'text-primary',
						)}
						to={to}
					>
						{t('nav.change-language')}
					</Link>

					<button
						ref={toggleRef}
						type="button"
						onClick={() => {
							analytics.track('Menu Toggled');
							onToggle();
						}}
						aria-expanded={expanded ? 'true' : 'false'}
						aria-controls={panelId}
						className={cn(
							'group -m-2.5 rounded-full p-2.5 transition',
							invert && 'hover:bg-primary-hover',
						)}
						aria-label="Toggle navigation"
					>
						<Icon
							name={icon}
							className={cn(
								'h-6 w-6',
								invert
									? 'text-primary-light group-hover:text-primary-light-hover'
									: 'text-neutral-darker group-hover:text-neutral-dark-hover',
							)}
						/>
					</button>
				</div>
			</div>
		</Container>
	);
}
