import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { Container } from '#app/components/container.tsx';
import { FadeIn } from '#app/components/fade-in.tsx';
import { LogoAvatar } from '#app/components/logo.tsx';
import { socialMediaProfiles } from '#app/components/social-media.tsx';
import { useChangeLanguageURL } from '#app/hooks/use-change-language.ts';
import { analytics } from '#app/utils/segment.ts';

const navigation: {
	title: string;
	links: {
		title: string | JSX.Element;
		href: string;
		onClick?: () => void;
	}[];
}[] = [
	{
		title: 'footer.nav.company',
		links: [
			{
				title: 'footer.nav.contact',
				href: '/contact',
				onClick: () =>
					analytics.track('Contact Us Clicked', { location: 'Footer' }),
			},
		],
	},
	{
		title: 'footer.nav.connect',
		links: socialMediaProfiles,
	},
];

export function Footer() {
	return (
		<Container as="footer" className="mt-24 w-full sm:mt-32 lg:mt-40">
			<FadeIn>
				<div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
					<Navigation />
				</div>
				<div className="mb-20 mt-24 flex flex-wrap items-end justify-between gap-x-6 gap-y-4 border-t border-primary-darker/10 pt-12">
					<Link to="/public" aria-label="Home">
						<LogoAvatar className="h-12 w-12" />
					</Link>
					<p className="text-sm text-primary-darker">
						© Upbeat, SL. {new Date().getFullYear()}
					</p>
				</div>
			</FadeIn>
		</Container>
	);
}

function Navigation() {
	const { t } = useTranslation();
	const { langSegment } = useChangeLanguageURL();
	return (
		<nav>
			<ul className="grid grid-cols-2 gap-8 sm:grid-cols-3">
				{navigation.map((section, sectionIndex) => (
					<li key={sectionIndex}>
						<div className="font-display text-sm font-semibold tracking-wider text-primary-darker">
							{t(section.title)}
						</div>
						<ul className="mt-4 text-sm text-primary-darker">
							{section.links.map((link, linkIndex) => (
								<li key={linkIndex} className="mt-4">
									<Link
										to={langSegment + link.href}
										onClick={link.onClick}
										className="transition hover:text-primary-darker"
									>
										{/* @ts-ignore */}
										{t(link.title)}
									</Link>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</nav>
	);
}
