import { type LoaderFunctionArgs } from '@remix-run/node';
import { Link, Outlet, useLocation } from '@remix-run/react';
import { motion, MotionConfig, useReducedMotion } from 'framer-motion';
import React, { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '#app/components/container.tsx';
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx';
import { SocialMedia } from '#app/components/social-media.tsx';
import { Icon } from '#app/components/ui/icon.tsx';
import { getLang, isSupportedLanguage } from '#app/utils/i18n.ts';
import { analytics } from '#app/utils/segment.ts';
import { Footer } from './__footer.tsx';
import { Header } from './__header.tsx';

export function loader({ params }: LoaderFunctionArgs) {
	const locale = getLang(params);
	const isSupported = isSupportedLanguage(locale);

	if (!isSupported) {
		throw new Response('Not Found', { status: 404 });
	}
	return null;
}
function Layout({ children }: { children: React.ReactNode }) {
	let panelId = useId();
	let [expanded, setExpanded] = useState(false);
	let openRef = useRef<React.ElementRef<'button'>>(null);
	let closeRef = useRef<React.ElementRef<'button'>>(null);
	let navRef = useRef<React.ElementRef<'div'>>(null);
	let shouldReduceMotion = useReducedMotion();
	const { t } = useTranslation();

	useEffect(() => {
		function onClick(event: MouseEvent) {
			if (
				event.target instanceof HTMLElement &&
				event.target.closest('a')?.href === window.location.href
			) {
				setExpanded(false);
			}
		}

		window.addEventListener('click', onClick);

		return () => {
			window.removeEventListener('click', onClick);
		};
	}, []);

	return (
		<MotionConfig transition={shouldReduceMotion ? { duration: 0 } : undefined}>
			<header>
				<div
					className="absolute left-0 right-0 top-2 z-40 pt-14"
					aria-hidden={expanded ? 'true' : undefined}
					// @ts-ignore (https://github.com/facebook/react/issues/17157)
					inert={expanded ? '' : undefined}
				>
					<Header
						panelId={panelId}
						icon="menu"
						toggleRef={openRef}
						expanded={expanded}
						onToggle={() => {
							setExpanded(expanded => !expanded);
							window.setTimeout(() =>
								closeRef.current?.focus({ preventScroll: true }),
							);
						}}
					/>
				</div>

				<motion.div
					layout
					id={panelId}
					style={{ height: expanded ? 'auto' : '0.5rem' }}
					className="relative z-50 overflow-hidden bg-primary pt-2"
					aria-hidden={expanded ? undefined : 'true'}
					// @ts-ignore (https://github.com/facebook/react/issues/17157)
					inert={expanded ? undefined : ''}
				>
					<motion.div layout className="bg-primary">
						<div ref={navRef} className="bg-primary pb-16 pt-14">
							<Header
								invert
								panelId={panelId}
								icon="x"
								toggleRef={closeRef}
								expanded={expanded}
								onToggle={() => {
									setExpanded(expanded => !expanded);
									window.setTimeout(() =>
										openRef.current?.focus({ preventScroll: true }),
									);
								}}
							/>
						</div>

						<div className="relative bg-primary before:absolute before:inset-x-0 before:top-0 before:h-px before:bg-neutral-800">
							<Container>
								<div className="grid grid-cols-1 gap-y-10 pb-16 pt-10 sm:grid-cols-2 sm:pt-16">
									<div>
										<h2 className="font-display text-base font-semibold text-white">
											{t('nav.address-title')}
										</h2>
										<address className="mt-6 text-sm not-italic text-primary-foreground">
											<Link
												onClick={() =>
													analytics.track('Clicked Email', {
														location: 'Header Menu',
													})
												}
												className="underline"
												to="mailto:hello@upbeat.works"
											>
												hello@upbeat.works
											</Link>
											<br />
											Zurbano 45, 1
											<br />
											28010 Madrid, Madrid
											<br />
											Spain
										</address>
									</div>
									<div className="sm:border-l sm:border-transparent sm:pl-16">
										<h2 className="font-display text-base font-semibold text-white">
											{t('nav.social-links-title')}
										</h2>
										<SocialMedia className="mt-6" invert />
									</div>
								</div>
							</Container>
						</div>
					</motion.div>
				</motion.div>
			</header>

			<motion.div
				layout
				style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
				className="bg-neutral-normal relative flex flex-auto overflow-hidden pt-14"
			>
				<motion.div
					layout
					className="relative isolate flex w-full flex-col pt-9"
				>
					<main className="w-full flex-auto">{children}</main>

					<Footer />
				</motion.div>
			</motion.div>
		</MotionConfig>
	);
}

export default function LandingLayout() {
	const location = useLocation();

	return (
		<Layout key={location.pathname}>
			<Outlet />
		</Layout>
	);
}

export function ErrorBoundary() {
	const location = useLocation();
	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: () => (
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-3">
							<div className="flex h-[200px] w-full items-center justify-center">
								<img
									alt="Upbeat Smiley logo"
									className="h-[100px] w-[100px] object-contain object-center"
									src="/img/smiley-red@2x.png"
								/>
							</div>
							<h1 className="font-display text-4xl text-primary-darker">
								Sorry, we can't find this page:
							</h1>
							<pre className="text-body-lg whitespace-pre-wrap break-all text-xl text-primary-darker">
								{location.pathname}
							</pre>
						</div>
						<Link to="/" className="text-primary-darker underline">
							<Icon name="arrow-left">Back to home</Icon>
						</Link>
					</div>
				),
			}}
		/>
	);
}
